<template>
    <div>
        <el-dialog :visible.sync="open" width="1000px"  @close="commitOnClose">
            <el-table  :data="data" >
                <el-table-column label="操作类型" prop="operType" :show-overflow-tooltip="true"/>
                <el-table-column label="代理商、机构编号" prop="childUserNo" :show-overflow-tooltip="true"/>
                <el-table-column label="操作对象名称" prop="childUserName" :show-overflow-tooltip="true"/>
                <el-table-column label="操作机具数量" prop="number" :show-overflow-tooltip="true"/>
                <el-table-column label="操作批次号" prop="batchNo" :show-overflow-tooltip="true"/>
                <el-table-column label="时间" prop="createTime" :show-overflow-tooltip="true"/>
                <el-table-column label="操作人名称" prop="userName" :show-overflow-tooltip="true" />  
                 
            </el-table>
        </el-dialog>
    </div>
    </template>
    
    <script>
    export default{
        data(){
            return {
                open:false
            }
        },
        props: {
            data: {
                type: [Array, Object, String]
            },
            onFresh: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            onFresh: function(value) {
                this.open = value;
            },
            data: function() { 
            }
        },
        mounted(){
    
        },
        methods:{
            commitOnClose() {
                this.$emit("on-close");
            },
        }
    }
    </script>